<!-- @Author: Yu_Bo 2023-09-07 10:54:40-->
<template>
    <div class="model_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="nav_txt" :class="navIndex == 1 ? 'nav_active' : ''" @click="navBtn(1)">模板市场</div>
            <div class="nav_txt" :class="navIndex == 2 ? 'nav_active' : ''" @click="navBtn(2)">模板分类</div>
        </div>
        <!--  -->
        <div class="model_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addClassify" size="small" icon="el-icon-plus" v-if="navIndex==2">添加分类</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==1" :clearable="true" placeholder="搜索模板名称"></el-input>
                    <el-select v-model="theme_type" size="small" @change="searchBtn" :clearable="true" placeholder="请选择归属类型" v-if="navIndex==1">
                        <el-option v-for="item in cat_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="aspect_ratio" size="small" @change="searchBtn" :clearable="true" placeholder="请选择模板样式" v-if="navIndex==1">
                        <el-option v-for="item in aspect_ratio_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="is_free" size="small" @change="searchBtn" :clearable="true" placeholder="是否免费" v-if="navIndex==1">
                        <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="is_vip" size="small" @change="searchBtn" :clearable="true" placeholder="是否会员" v-if="navIndex==1">
                        <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==2" :clearable="true" placeholder="搜索分类名称"></el-input>
                </div>
            </div>
            <!-- 模板列表 -->
            <div class="main_table" v-show="navIndex == 1">
                <tem-list ref="model"></tem-list>
            </div>
            <div class="main_table" v-show="navIndex == 2">
                <classify-list ref="classify" :parent_id="3"></classify-list>
            </div>
        </div>

    </div>
</template>

<script>
import TemList from '../components/template/template_list'//模特列表
import ClassifyList from '../components/classify/index.vue'//分类列表
export default {
    components: { TemList, ClassifyList },
    props: {},
    data() {
        return {
            navIndex: 1,
            // 名称
            name: '',
            // 归属类型
            theme_type: '',
            cat_list: [],
            // 是否免费
            is_free: '',
            freeList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            // 是否会员
            is_vip: '',
            vipList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            // 模板样式
            aspect_ratio: '',
            aspect_ratio_list: [],
        };
    },

    created() { },

    mounted() {
        this.getCat()
    },

    methods: {
        // 参数
        getCat() {
            var that = this
            that.$marketApi.marketTemplateParams().then(res => {
                if (res.code == 1000) {
                    // 归属类型
                    that.cat_list = res.result.cat_list
                    // 模板样式
                    that.aspect_ratio_list = res.result.aspect_ratio
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 切换
        navBtn(val) {
            this.navIndex = val
            this.name = ''
            this.theme_type = ''
            this.is_free = ''
            this.is_vip = ''
            this.aspect_ratio = ''
            this.searchBtn()
            this.getCat()
        },
        // 添加分类
        addClassify() {
            this.$refs.classify.addClassify()
        },
        // 搜索
        searchBtn() {
            if (this.navIndex == 1) {
                // 模板
                var obj = {
                    name: this.name,
                    theme_type: this.theme_type,
                    is_free: this.is_free,
                    is_vip: this.is_vip,
                    aspect_ratio: this.aspect_ratio,
                }
                this.$refs.model.search(obj)
            } else if (this.navIndex == 2) {
                // 分类
                var obj = {
                    name: this.name,
                }
                this.$refs.classify.search(obj)
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.model_vue {
    width: 100%;

    .nav_txt {
        cursor: pointer;
        margin-right: 40px;
    }

    .nav_active {
        color: #2e4bf2;
    }

    .model_main {
        width: 100%;
        padding: 0 20px;

        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cond_left {
                display: flex;
                align-items: center;

                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }

                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }

                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>